import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import NivelToner from '../../components/DisplayNivelToner/DisplayNivelToner'
import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help'
import CamIcon from '@material-ui/icons/VideoCall'
import GeradorLog from '../../providers/GeradorLog'
import PrintIcon from '@material-ui/icons/Print'


const SolicitacoesChamados = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)
    const [paginas, setPadinas]= useState(1)

    const [pedidos, setPedidos] = React.useState([]);


   // const dadosEmpresa = props.location.state.dadosEmpresa;

    const refDataInicial =useRef();
    const refDataFinal = useRef();
    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    React.useEffect(()=>{
        console.log(props.location.state.dadosEmpresa)
    },[])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // getData();
       console.log(props.location.state)
       if(props.location.state.filtrar && props.location.state.numeroSerie){
        if(props.location.state.numeroSerie){
            refTipoFiltro.current.value = 'numeroSerie'
            refFiltro.current.value = props.location.state.numeroSerie 
            setTimeout(()=>{
                getData();
            },500
            )
        }
       }
    },[])

    useEffect(()=>{
      //  gerarLogSite();
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)
    const gerarLogSite = () =>{
        GeradorLog(dadosUsuario, '');
    }

    const getData = () =>{
        setLoading(true)
        Conexao.post('chamadosController/getSolicitacoesAtendimento', {
            codigoCliente : dadosUsuario.TB01066_CODCLI,
            contratos : dadosUsuario.contratos ? dadosUsuario.contratos.map(function(contrato){
                return contrato.numeroContrato
            }) : [],             
            codigoEmpresa : codigoEmpresa, 
            dataInicial : refDataInicial.current ?refDataInicial.current.value : '', 
            dataFinal :  refDataFinal.current ? refDataFinal.current.value : '', 
            tipoFiltro :  refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro :  refFiltro.current ? refFiltro.current.value : '', 
            status : refStatusPedido.current ? refStatusPedido.current.value : 'G',
            registros : rowsPerPage, 
            pagina : page,
            now : 't'
        }).then(response=>{
            console.log(response.data)
            if(response.data.status =='OK'){
                
                setPedidos(response.data.payload)
                setRegistros(response.data.payload)
            }else{
                setPedidos([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error=>{
            setPedidos([])
            setRegistros(0)
            setLoading(false)
        })
    }




    

    return <Principal {...props} botaoAtivo = 'solicitacoes-chamado' loading={loading}>
        <div className='container-children'>
        
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Confirmação de Abertura de Ordem de Serviço via Portal Web</Typography>
                </Breadcrumbs>
                </div>
                
               {/*} <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage} 
                    labelRowsPerPage="Linhas por página:"                
                />*/}
                
            </div>
           
            <Divider/>
            <br/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
            <Grid item xs = {2}>
                    <TextField 
                        label = 'Incial'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        type={'date'}
                        inputRef={refDataInicial}/>
                </Grid>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Final'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        type={'date'}
                        inputRef={refDataFinal}/>
                </Grid>
                
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        disabled={props.location.state.filtrar}
                        inputRef={refTipoFiltro}>
                            <option value = 'placa'>Patrimônio</option>
                            <option value = 'numeroSerie'>Série</option>                                                        
                            <option value = 'modelo'>Modelo</option>
                            <option value = 'setor'>Setor</option>
                    </TextField>
                </Grid>
                <Grid item xs = {4}>
                    <TextField 
                        label = 'Filtro'
                        disabled={props.location.state.filtrar}
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                getData();
                            }
                        }}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'G'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
            <Typography style={{marginTop : '5px'}} variant='caption'>* Solicitações de Atendimento Técnico (Chamados) realizados pelo portal Web, para consultar todas as Ordens de Serviço abertas consulte em <Link to={{pathname : "/chamados/empresa", state:{
                        ...props.location.state
                    }}}><span className='text-link-bold'>Chamados Abertos</span></Link>.</Typography>
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                    <TableHead>
                        <TableCell align='center' style={{width: '5%'}}>NUM</TableCell>  
                        <TableCell align='center' style={{width: '8%'}}>Data</TableCell>
                        
                        <TableCell align='center' style={{width: '5%'}}>Pat</TableCell>
                        <TableCell align='left'   style={{width: '20%'}}>Equipamento</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Soliciante</TableCell>
                        <TableCell align='left' style={{width: '30%'}}>Defeito Relatado</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Tipo</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Status</TableCell>
                        
                      
                        
                    </TableHead>
                    <TableBody>
                        {
                            pedidos && pedidos.map(function(pedido, i){
                                return <TableRow key ={i}>
                                <TableCell align='center' ><Typography variant='caption' style= {{fontSize:'10px'}}><strong>S{pedido.codigoChamado}</strong></Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' style= {{fontSize:'10px'}}>{pedido.cadastro}</Typography></TableCell>
                                
                                <TableCell align='center' >
                                    <Typography variant='caption'  style= {{fontSize:'10px'}}><span className='display-placa' >{pedido.TB02112_PAT}</span></Typography>
                                </TableCell>
                                <TableCell align='left' >
                                    <Typography variant='caption' className='texto-limitado'  style= {{fontSize:'10px'}}>{pedido.TB01010_NOME}</Typography><br/>
                                    <Typography variant='caption'  style= {{fontSize:'10px'}}><strong>{pedido.numserie}</strong></Typography>
                                </TableCell>
                                <TableCell align='center'><Typography variant='caption' style= {{fontSize:'10px'}} className='texto-limitado' >{pedido.contato}</Typography></TableCell>
                                <TableCell align='left' ><Typography variant='caption'  style= {{fontSize:'10px'}} className='texto-limitado' >{pedido.obs+'...'}</Typography></TableCell>
                                <TableCell align='center' ><Typography variant='caption' style= {{fontSize:'10px'}} className='texto-limitado' >{pedido.operador}</Typography></TableCell>
                                <TableCell align='center' ><Typography variant='caption' style= {{fontSize:'10px'}} className='texto-limitado'>{
                                pedido.abertaDataService =='f' ? 'AGUARDANDO PROCESSAMENTO' :'EFETIVADA'
                                }</Typography></TableCell>
                                
                               
                            </TableRow>
                            })
                        }
                    </TableBody>
                </Table>      
        </div>
        <div className='botaoAcoes'>
            <div>
            <Tooltip title ='Abrir uma nova solicitação de suprimentos'>
                <Fab color="primary" aria-label="add"   elevation={10} onClick={()=>{
                        props.history.push({
                            pathname : '/equipamentos/empresa', state:{
                                ...props.location.state
                            }
                        })
                    }}>
                    <AddIcon />
                </Fab>
            </Tooltip>   
            </div>
            <div><Tooltip title ='Relatório de Ordens de Serviço'>
                <Fab color="secondary" aria-label="add" style={{marginTop :'5px'}}   elevation={10} onClick={()=>{
                        props.history.push({
                            pathname : '/relatorios/empresa', state:{
                                ...props.location.state
                            }
                        })
                    }}>
                    <PrintIcon />
                </Fab>
                </Tooltip> 
            </div>            
        </div>
    </Principal>
}

export default withRouter(SolicitacoesChamados)